/* BTDTextareaField */

.BTDTextareaField {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
}

.BTDTextareaField.medium {
    width: calc(50% - 10px);
}

.BTDTextareaField.small {
    width: calc(25% - 20px);
}

.BTDTextareaField__description {
}

.BTDTextareaField__label {
    background-color: #fff;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    position: absolute;
    left: 20px;
    top: 20px;
    transition: all 0.3s;
    z-index: -1;
}

.BTDTextareaField__label.active {
    font-size: 11px;
    left: 15px;
    padding: 0 5px 3px 5px;
    top: -5px;
    z-index: 9;
}

.BTDTextareaField__input {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.4;
    outline: none;
    padding: 18px 20px;
    width: 100%;
}

.BTDTextareaField__input:focus {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    outline: 0;
}

.BTDTextareaField__error {
    color: red;
}
