/* TaskViewScreen CSS */

.TaskViewScreen {
}

.TaskViewScreen__nameWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.TaskViewScreen__name {
    line-height: 1.3;
}

.TaskViewScreen__buttons {
    align-items: center;
    display: grid;
    grid-template-columns: auto max-content;
    gap: 15px 20px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.TaskViewScreen__buttons2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}


.TaskViewScreen__buttons__statuses {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	width: 560px;
}

.TaskViewScreen__buttons__statuses__status {
	color: #fff;
    font-size: 13px;
	cursor: pointer;
    opacity: 0.4;
	padding: 10px 16px 10px 24px;
	position: relative;
}

.TaskViewScreen__buttons__statuses__status__leftTriangle {
    border-bottom: 17.5px solid transparent;
	border-top: 17.5px solid transparent;
    border-left: 8px solid #fff;
    height: 0;
    position: absolute;
    left: 0px;
	top: 0px;
    width: 0;
}

.TaskViewScreen__buttons__statuses__status__rightTriangle {
    border-bottom: 17.5px solid transparent;
	border-top: 17.5px solid transparent;
    height: 0;
    position: absolute;
    left: 100%;
	top: 0;
    width: 0;
}

.TaskViewScreen__buttons__statuses__status__input {
	display: none;
}

.TaskViewScreen__buttons__statuses__status:has(input:checked) {
    opacity: 1;
	transform: scaleY(1.2);
} 

.TaskViewScreen__buttons__icons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 200px;
}

.TaskViewScreen__buttons__icon {
    cursor: pointer;
    height: auto;
    /* transform: translateY(-2px); */
    width: 35px;
}

.TaskViewScreen__buttons__users {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
    transform: translateY(2px);
	width: 100px;
}

.TaskViewScreen__buttons__users__user {
	align-items: center;
	border-radius: 100%;
	color: #fff;
	cursor: pointer;
	display: flex;
	height: 45px;
	justify-content: center;
    opacity: 0.3;
	width: 45px;
}

.TaskViewScreen__buttons__users__user__input {
	display: none;
}

.TaskViewScreen__buttons__users__user:has(input:checked) {
	font-weight: 700;
    opacity: 1;
	transform: scale(1.1);
} 

.TaskViewScreen__label {
    margin-bottom: 10px;
}

.TaskViewScreen__descriptionWrapper {
    height: 250px;
}

.TaskViewScreen__description {
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    cursor: pointer;
    height: 200px;
    line-height: 1.3;
    margin-bottom: 30px;
    overflow: scroll;
    padding: 20px;
}

.TaskViewScreen__attachments {
    column-gap: 40px;
    display: grid;
    grid-template-columns: 350px auto;
    margin: 20px 0;
}

.TaskViewScreen__attachments__attachment {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.TaskViewScreen__attachments__attachment__name {
    cursor: pointer;
    transition: all 0.3s;
}

.TaskViewScreen__attachments__attachment__name:hover {
    opacity: 0.7;
}

.TaskViewScreen__times {
    min-height: 100px;
}

.TaskViewScreen__subtitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.TaskViewScreen__times__time {
    align-items: center;
    border-radius: 4px;
    gap: 10px 20px;
    display: grid;
    grid-template-columns: auto auto 120px 200px 28px;
    margin-bottom: 10px;
}