/* Tab CSS */

.Tab {
    background-color: #6B6A6A;
    border-radius: 50rem;
    color: #fff;
    line-height: 1;
    padding: 7px 15px;
    width: max-content;
}

.Tab--colour-orange {
    background-color: #FF8000;
}

.Tab--colour-green {
    background-color: #59B200;
}

.Tab--colour-red {
    background-color: #FD1F1F;
}

.Tab--colour-blue {
    background-color: #0454A8;;
}

.Tab--colour-pink {
    background-color: #FF38F4;
}

.Tab--colour-yellow {
    background-color: #D87F2D;
}