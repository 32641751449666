/* Layout CSS */

.DefaultLayout__main {
    background-color: #1F2124;
	display: flex;
    min-height: 80vh;
}

.SettingsLayout__sidebar {
    padding: 80px 40px;
}

.SettingsLayout__sidebar__item {
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 30px;
    transition: all 0.3s;
}

.SettingsLayout__sidebar__item:hover {
    opacity: 0.8;
}

.SettingsLayout__sidebar__item.active {
    font-size: 26px;
    font-weight: 600;
}

.SettingsLayout__main {
    padding: 80px 0;
    width: 100%;
}