/* BTDEditor CSS */

.BTDEditor {
    margin-bottom: 30px;
}

.BTDEditor__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}