/* Search Result CSS */

.SearchResult {
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    transition: all 0.3s;
}

.SearchResult:hover {
    opacity: 0.75;
}

.SearchResult__top {
    align-items: center;
    display: grid;
    grid-template-columns: auto 70px 40px;
}


.SearchResult__top__name {
    font-size: 16px;
}

.SearchResult__top__status {
    align-items: center;
    border-radius: 6px;
    color: #fff;
    display: flex;
    font-size: 13px;
    justify-content: center;
    padding: 5px;
    width: 35px;
}

.SearchResult__top__user {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 13px;
    height: 27px;
    justify-content: center;
    padding: 5px;
    width: 27px;
}
