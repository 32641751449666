/* TaskListItem CSS */

.TaskListItem {
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s;
}

.TaskListItem__top {
    padding: 20px 20px 70px 20px;
}

.TaskListItem__top__icons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.TaskListItem__top__icon {
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 20px;
    margin: 0 0 0 auto;
    width: 20px;
}

.TaskListItem__top__name {
    line-height: 1.3;
    text-align: center;
}

.TaskListItem__bottom {
    align-items: center;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    position: absolute;
    width: 100%;
}