/* SearchIcon CSS */

.SearchButton {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 50px;
	cursor: pointer;
	display: flex;
	height: 50px;
	justify-content: center;
	padding: 13px 10px 10px 10px;
	width: 50px;
}

.SearchButton:hover {
	border: 1px solid #505C72;
}