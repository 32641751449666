.Skeleton {
    animation: wavy 1s infinite linear;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    display: block;
    margin-bottom: 5px;
}

@keyframes wavy {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}
