/* Overelay CSS */

.Overlay {
	backdrop-filter: blur(3px);
	background-color:  rgba(0, 0, 0, 0.3);
	bottom:  0;
	left:  0;
	position:  fixed;
	right:  0;
	top:  0;
	z-index: 99;
}

.Overlay-transparent {
	background-color: transparent;
}