/* LoginScreen CSS */


.LoginScreen {
    margin: 0 auto;
    max-width: 700px;    
}

.LoginScreen__header {
	background-color: #1F2124;
	border-bottom: 1px solid #ddd;
	display: block;
    padding: 30px;
    text-align: center;
}

.LoginScreen__header p {
    font-size: 40px;
    font-weight: 600;
}

.LoginScreen__header__logo {
    height: auto;
    width: 150px;
}

.LoginScreen__main {
    background-color: #fff;
    padding: 40px;
}
