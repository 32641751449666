/* TimeListItem CSS */

.TimeListItem.card {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: auto 200px 200px 200px 35px 25px;
    height: 65px;
    margin-bottom: 15px;
    max-width: 1400px;
    padding: 0px 20px;
}

.TimeListItem__user {
    align-items: center;
    border-radius: 50%;
    color: '#fff';
    display: flex;
    font-weight: 600;
    justify-content: center;
    height: 35px;
    width: 35px;
}