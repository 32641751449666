/* Pagination CSS */

.BTDPagination {
    align-items: center;
    display: flex;
}

.BTDPagination__list {
    display: flex;
}

.BTDPagination__list__number {
    align-items: center;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 34px;
    justify-content: center;
    margin: 0 8px;
    transition: all 0.25s;
    width: 34px;
}

.BTDPagination__list__number:hover {
    opacity: 0.75;
}

.BTDPagination__list__number.active {
    background-color: #6F6F6F;
    border: 1px solid #6F6F6F;
    color: #fff;
}

.BTDPagination__button {
    background-size: contain;
    cursor: pointer;
    height: 30px;
    transition: all 0.25s;
    width: 30px;
}

.BTDPagination__button.disabled {
    visibility: hidden;
}

.BTDPagination__button:hover {
    opacity: 0.75;
}

.BTDPagination__button-prev {
    background-image: url('./left.svg');
}

.BTDPagination__button-next {
    background-image: url('./right.svg');
}