/* AttachmentListItem CSS */

.AttachmentListItem.card {
    align-items: center;
    display: grid;
    grid-template-columns: auto 40% 100px 35px 25px;
    height: 65px;
    margin-bottom: 15px;
    max-width: 1400px;
    padding: 0px 20px;
}

.AttachmentListItem__form {
    align-items: center;
    display: flex;
}

.AttachmentListItem__colour {
    position: relative;
}

.AttachmentListItem__colour__icon {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    transition: all 0.3s;
    width: 70px;
}

.AttachmentListItem__colour__icon:hover {
    opacity: 0.7;
}

.AttachmentListItem__colour__menu {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    color: #253551;
    left: calc(100% - 5px);
    padding: 20px 20px;
    position: absolute;
    top: calc(100% - 5px);
    width: max-content;
    z-index: 99;
}

.AttachmentListItem__colour__menu--position-left {
    left: auto;
    right: 100%;
}