/* ImageUpload CSS */

.ImageUpload {
	align-items: center;
	border: 1px solid #E3E3E3;
	border-radius: 4px;
	height: 100%;
	justify-content: center;
	margin-bottom: 30px;
	min-height:  185px;
	max-width: 350px;
	padding: 20px;
	position: relative;
	width: 100%;
}

.ImageUpload__label {
	background-color: #fff;
	font-size: 11px;
    left: 15px;
    padding: 5px;
	position: absolute;
    top: -11px;
	z-index: 9;
}

.ImageUpload-active {
	transform: scale(1.1);
}

.ImageUpload__dropzone {
	background-color:  #F3F5F7;
	background-position: center;
	background-repeat: no-repeat;
	background-size:  cover;
	border-radius: 4px;
	color:  #888;
	cursor: pointer;
	height:  100%;
	font-size:  20px;
	min-height: 185px;
	padding:  34px 30px 0 30px;
	position: relative;
	text-align: center;
}

.ImageUpload__dropzone__icon {
	align-items: center;
	background-image:  url('./upload.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 35px;
	margin: 0 auto 10px auto;
	width: 50px;
}

.ImageUpload__dropzone__icon-error {
	background-image: url('./error.svg');
}

.ImageUpload__dropzone__title {
	color: #2A3042;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 15px;
}

.ImageUpload__dropzone__text {
	color: #2A3042;
	font-size: 15px;
	font-weight: 400;
	opacity: 47%;
}

.ImageUpload__dropzone__close {
	background-color: #fff;
	background-image: url('./close.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px 14px;
	border-radius: 50%;
	cursor: pointer;
	height: 28px;
	padding: 5px;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 28px;
}
