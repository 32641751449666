.Avatar {
	align-items: center;
	color: #fff;
	display: flex;
	font-weight: bold;
	justify-content: center;
	position: relative;
}

.Avatar__image {
	align-items: center;
	background-color: #666;
	background-size: cover;
	border-radius: 50px;
	border-radius: 50px;
	cursor: pointer;
	display: flex;
	height: 43px;
	justify-content: center;
	width: 43px;
}

.Avatar__name {
	margin: 0 0 0 20px;
}

.Avatar__dropdown {
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 8px rgba(0,0,0, 0.263057);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px 20px 20px 20px;
	position: absolute;
	right: -30px;
	top: 61px;
	width: 195px;
	z-index: 99;
}

.Avatar__dropdown__image {
	align-items: center;
	background-size: cover;
	border-radius: 50px;
	display: flex;
	height: 43px;
	justify-content: center;
	margin-bottom: 10px;
	width: 43px;
}

.Avatar__dropdown__triangle {
	background-color: #fff;
	height: 34px;
	position: absolute;
	left: 120px;
	transform: rotate(45deg) translateY(-7px);
	top: 0;
	width: 34px;
}

.Avatar__dropdown__name {
	font-size: 16px;
	margin-bottom: 20px;
}

.Avatar__dropdown__link {
	align-items: center;
	color: #1976d2;
	display: flex;
	margin-bottom: 20px;
}

.Avatar__dropdown__link:hover {
	opacity: 0.7;
}