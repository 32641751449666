/* TaskList CSS */

.TaskList {
    column-gap: 40px;
    display: grid;
    grid-template-columns: repeat(5, calc(20% - 32px));
}

@media only screen and (max-width: 2400px) {
    
    .TaskList {
        grid-template-columns: repeat(4, calc(25% - 30px));
    }
}

@media only screen and (max-width: 2000px) {
    
    .TaskList {
        grid-template-columns: repeat(3, calc(33.3333% - 26.666666px));
    }
}

@media only screen and (max-width: 1200px) {
    
    .TaskList {
        grid-template-columns: repeat(2, calc(50% - 40px));
    }
}

@media only screen and (max-width: 850px) {
    
    .TaskList {
        column-gap: 40px;
        grid-template-columns: repeat(2, calc(50% - 20px));
    }
}

@media only screen and (max-width: 800px) {
    
    .TaskList {
        display: block;
    }
}
