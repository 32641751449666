/* TaskListScreen CSS */

.TaskListScreen__sidebar {
    padding: 80px 0px;
    position: relative;
    width: 340px;
}

.TaskListScreen__sidebar__scrollDiv {
    height: 88vh;
    overflow-y: scroll;
    overflow-x: visible;
    position: sticky;
    scrollbar-width: none;
    top: 20px;
}

.TaskListScreen__sidebar__scrollDiv::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.TaskListScreen__sidebar__item {
    align-items: center;
    background-color: #888;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    line-height: 1.2;
    min-height: 75px;
    justify-content: center;
    margin: 0 auto 25px auto;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    width: 180px;
}

.TaskListScreen__sidebar__item:hover {
    opacity: 0.8;
}

.TaskListScreen__sidebar__heading {
    font-size: 18px;
    margin: 50px 0 15px 0;
    opacity: 0.5;
}

.TaskListScreen__content {
	padding: 80px 0;
    width: 100%;
}

.TaskListScreen__statuses {
    padding: 80px 0px;
    width: 270px;
}

.TaskListScreen__statuses__item {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 19px;
    font-weight: 600;
    height: 150px;
    justify-content: center;
    line-height: 1.3;
    margin: 0 auto 20px auto;
    padding: 20px 10px 0 10px;
    position: relative;
    transition: all 0.3s;
    text-align: center;
    width: 120px;
}

.TaskListScreen__statuses__item:hover {
    opacity: 0.8;
}

.TaskListScreen__statuses__item__topTriangle {
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 20px solid #1F2124;
    height: 0;
    position: absolute;
    top: 0px;
    width: 0;
}

.TaskListScreen__statuses__item__bottomTriangle {
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    height: 0;
    position: absolute;
    top: 100%;
    width: 0;
}