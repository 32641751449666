/* BTDColourPicker */

.BTDColourPicker {
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    font-size: inherit;
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    width: max-content;
}

.BTDColourPicker:focus {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    outline: 0;
}

.BTDColourPicker.medium {
    width: calc(50% - 10px);
}

.BTDColourPicker.small {
    width: calc(25% - 20px);
}

.BTDColourPicker__label {
    background-color: #fff;
    color: rgba(0,0,0,0.5);
    font-size: 11px;
    padding: 0 5px;
    position: absolute;
    left: 15px;
    top: -5px;
    transition: all 0.3s;
    z-index: 9;
}

.BTDColourPicker__hash {
    position: absolute;
    transform: translate(10px, 23px);
}

.BTDColourPicker input {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.3;
    max-width: 200px;
    outline: none;
    padding: 18px 20px 18px 30px;
    width: 100%;
}

.BTDColourPicker__error {
    color: red;
}
