/* BTDDatepicker */

.BTDDatepicker {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}

.BTDDatepicker__label {
    background-color: #fff;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    position: absolute;
    left: 20px;
    top: 13px;
    transition: all 0.3s;
    z-index: 2;
}

.Settings__filter .BTDDatepicker__label {
    top: 22px;
}

.BTDDatepicker__label.active {
    font-size: 12px;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.BTDDatepicker__input {
    background-color: #fff;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    outline: none;
    padding: 8px 20px;
    width: 100%;
}

.Settings__filter .BTDDatepicker__input {
    padding: 17px 20px;
}

.BTDDatepicker__input:focus {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    outline: 0;
}

.BTDDatepicker__popper {
    width: max-content;
    z-index: 999;
}

.BTDDatepicker__calendar {
    /* z-index: 99; */
}
.BTDDatepicker__error {
}
