
.LoginLayout {
	align-items: center;
	background-color: #4E5259;
	display: flex;
	height: 100vh;
	/* padding: 20vh 0; */
	margin: 0 auto;
	width: 100%;
}
