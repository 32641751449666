/* BTDFAB CSS */

.BTDFAB {
    align-items: center;;
    background-color: #fff;
    border-radius: 50%;
    bottom: 100px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 32px;
    font-weight: 200;
    height: 60px;
    justify-content: center;
    position: fixed;
    right: 60px;
    width: 60px;
    transition: all 0.35s;
}

.BTDFAB:hover {
    opacity: 0.75;
}

