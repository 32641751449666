/* HelpscoutListItem CSS */

.HelpscoutListItem.card {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 80px 100px auto 38% 100px;
    height: 65px;
    margin-bottom: 15px;
    max-width: 1400px;
    padding: 0px 20px;
    width: 1400px;
}
