/* Search CSS */

.Search {
	background-color: #1F2124;
	box-shadow: 0px -3px 6px rgba(0,0,0, 0.263057);
	height: 100vh;
	overflow: scroll;
	padding: 30px;
	position: absolute;
	right: 0;
	top: 0px;
	width: 400px;
	z-index: 999;
}

@Media only screen and (max-width: 500px) {

	.Search {
		width: 350px;
	}
}

@Media only screen and (max-width: 380px) {

	.Search {
		width: 320px;
	}
}