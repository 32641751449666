/* RecurringTaskListItem CSS */

.RecurringTaskListItem.card {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: auto 180px 180px 180px 100px 100px 70px 100px 25px;
    margin-bottom: 30px;
    max-width: 1500px;
    padding: 10px 20px;
}

.RecurringTaskListItem__text {
    cursor: pointer;
}

.RecurringTaskListItem__form {
    align-items: center;
    display: flex;
}

.RecurringTaskListItem__user {
    position: relative;
}

.RecurringTaskListItem__user__icon {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 45px;
    justify-content: center;
    text-align: center;
    transition: all 0.3s;
    width: 45px;
}

.RecurringTaskListItem__user__icon:hover {
    opacity: 0.7;
}

.RecurringTaskListItem__colour__menu {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    color: #253551;
    padding: 20px 20px;
    position: absolute;
    right: 100%;
    top: calc(100% - 5px);
    width: max-content;
    z-index: 99;
}

.RecurringTaskListItem__colour__menu--position-left {
    left: auto;
    right: 100%;
}

.RecurringTaskListItem__colour__abbr {
    cursor: pointer;
    max-width: 60px;
    text-align: center;
    transition: all 0.3s;
}

.RecurringTaskListItem__colour__abbr:hover {
    opacity: 0.7;
}
