/* BTDNumberField */

.BTDNumberField {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
    width: calc(25% - 20px);
    z-index: 1;
}

.BTDNumberField--height-slim {
    margin-bottom: 10px;
}

.BTDNumberField.medium {
    width: calc(50% - 10px);
}

.BTDNumberField.large {
    width: 100%;
}

.BTDNumberField__description {
}

.BTDNumberField__label {
    background-color: #fff;
    bottom: 5px;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    position: absolute;
    left: 20px;
    top: 5px;
    transition: all 0.3s;
    z-index: -1;
}

.BTDNumberField__label__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    transition: all 0.3s;
}

.BTDNumberField__label.active {
    bottom: auto;
    font-size: 11px;
    height: auto;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDNumberField__input {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    outline: none;
    padding: 18px 20px;
    width: 100%;
}

.BTDNumberField__input:focus {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    outline: 0;
}

.BTDNumberField__input--height-slim {
    padding: 9px 20px;
}

.BTDNumberField__error {
    color: red;
    transform: translateY(-20px);
}
