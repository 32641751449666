
.BTDButton {
	align-items: center;
	border:  0;
	border-radius: 4px;
	display:  flex;
	color:  #FFF;
	cursor: pointer;
	font-family: inherit;
	font-size: 18px;
	font-weight:  500;
	outline:  0;
	padding:  15px 30px;
	position: relative;
	text-align: center;
	transition: all 0.3s;
	max-width:  max-content;
	width: 100%;
}

.BTDButton:hover {
	opacity:  0.8;
}

.BTDButton--size-small {
	padding:  10px 15px;
	font-size:  16px;
	font-weight:  500;
}

.BTDButton--size-large {
	display: block;
	font-size:  18px;
	font-weight:  500;
	max-width: 100%;
	padding:  18px 80px;
	width: 100%;
}

.BTDButton--type-rounded {
	border-radius: 50px;
}

.BTDButton.disabled {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.26);
	cursor: default;
}

.BTDButton.disabled:hover {
	opacity: 1;
}

.BTDButton.startIcon {
	padding: 15px 24px 15px 20px;
}

/* Colour Variants
-----------------------------------------------*/

.BTDButton--colour-blue, .BTDButton--colour-default {
	background-color:  #1976d2;
}

.BTDButton--colour-white {
	background-color:  #FFF;
}

.BTDButton--colour-orange {
	background-color:  #EE7523;
}

.BTDButton--colour-green {
	background-color:  #5DA12D;
}

.BTDButton--colour-red, .BTDButton--type-delete {
	background-color:  #C33C3C;
}

/* types */

.BTDButton--type-outlined {
	background-color: #fff;
	border: 1px solid #1565c0;
	color: #1565c0;
}

.BTDButton--type-outlined.BTDButton--colour-white {
	background-color: transparent;
	border: 1px solid #FFF;
	color: #FFF;
}

.BTDButton--type-outlined.BTDButton--colour-orange {
	background-color: #fff;
	border: 1px solid #EE7523;
	color: #EE7523;
}

.BTDButton--type-outlined.BTDButton--colour-green {
	background-color: #fff;
	border: 1px solid #5DA12D;
	color: #5DA12D;
}

.BTDButton--type-outlined.BTDButton--colour-red {
	background-color: #fff;
	border: 1px solid #C33C3C;
	color: #C33C3C;
}

.BTDButton--type-delete {
	opacity:  0.5;
	margin-top:  120px;
}


/* icons */

.BTDButton__icon {
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	margin-right: 8px;
	height: 18px;
	width: 18px;
}
