/* BTDMenu CSS */

.BTDMenu {
    position: relative;
}

.BTDMenu__icon {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    transition: all 0.3s;
    width: 28px;
}

.BTDMenu__icon:hover {
    opacity: 0.7;
}

.BTDMenu__menu {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    color: #253551;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: calc(100% + 5px);
    width: max-content;
    z-index: 99;
}

.BTDMenu__menu--position-left {
    left: auto;
    right: 100%;
}

.BTDMenu__menu p, .BTDMenu__menu li, .BTDMenu__menu div {
    color: #253551;
    cursor: pointer;
    margin-bottom: 18px;
    transition: all 0.3s;
}

.BTDMenu__menu p:last-child, .BTDMenu__menu li:last-child, .BTDMenu__menu div:last-child {
    margin-bottom: 0;
}

.BTDMenu__menu p:hover, .BTDMenu__menu li:hover, .BTDMenu__menu div:hover {
    opacity: 0.7;
}