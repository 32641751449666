.Header {
	align-items: center;
	background-color: #1F2124;
	border-bottom: 1px solid #555;
	color: #FFF;
	display: flex;
	justify-content: space-between;
	padding: 20px 0 30px 0;
	position: relative;
	z-index: 99;
}

.Header__logo {
	font-size: 60px;
	font-weight: 700;
}

.Header__vlogo {
	transform: translateY(6px);
	width: 64px;
}

.Header__ologo {
	transform: translateY(4px);
	width: 40px;
}

.Header__form {
	background-color: #fff;
	border-radius: 4px;
	padding: 20px;
}

.Header__form__buttons {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 550px;
}

.Header__form__statuses {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	width: 250px;
}

.Header__form__statuses__status {
	align-items: center;
	color: #fff;
	cursor: pointer;
	display: flex;
	height: 35px;
	justify-content: center;
	opacity: 0.6;
	padding-left: 7px;
	position: relative;
	width: 55px;
}

.Header__form__statuses__status__leftTriangle {
    border-bottom: 17.5px solid transparent;
	border-top: 17.5px solid transparent;
    border-left: 8px solid #fff;
    height: 0;
    position: absolute;
    left: 0px;
	top: 0px;
    width: 0;
}

.Header__form__statuses__status__rightTriangle {
    border-bottom: 17.5px solid transparent;
	border-top: 17.5px solid transparent;
    height: 0;
    position: absolute;
    left: 100%;
	top: 0;
    width: 0;
}

.Header__form__statuses__status__input {
	display: none;
}

.Header__form__statuses__status:has(input:checked) {
	font-weight: 700;
	opacity: 1;
	transform: scaleY(1.2);
} 

.Header__form__users {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	/* width: 100px; */
}

.Header__form__users__user {
	align-items: center;
	border-radius: 100%;
	color: #fff;
	cursor: pointer;
	display: flex;
	height: 45px;
	justify-content: center;
	margin-left: 10px;
	opacity: 0.6;
	width: 45px;
}

.Header__form__users__user__input {
	display: none;
}

.Header__form__users__user:has(input:checked) {
	font-weight: 700;
	opacity: 1;
	transform: scale(1.1);
} 

.Header__right {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 250px;
	width: 100%;
}

.Header__helpscout {
	background-image: url('../../assets/helpscout-logo.svg');
	background-repeat: no-repeat;
	background-size: contain;
	height: 34px;
	width: 34px;
}

.Header__Search {
	/* margin-right: 30px; */
}

.Header__settings {
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 50px;
	justify-content: center;
	width: 50px;
}

.Header__settings img {
	height: 25px;
	width: 25px;
}

.Header__settings:hover {
	border: 1px solid #505C72;
}

.Header__Avatar {
	/* margin-left: 30px; */
}

/*
* Hamburger
*/
.hamburger {
	background-color: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	display: none;
	font: inherit;
    margin: 0 0 -5px auto;
	outline: none;
	overflow: visible;
	padding: 0;
	text-transform: none;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	z-index: 999999999;
}

.hamburger:hover, 
.hamburger.is-active:hover {
	opacity: 0.7;
	outline: none;
}

.hamburger.is-active-true .hamburger-inner,
.hamburger.is-active-true .hamburger-inner::before,
.hamburger.is-active-true .hamburger-inner::after {
	background-color: #fff;
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	outline: none;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px; 
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
	width: 40px;
	height: 2px;
	background-color: #fff;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease; 
}

.hamburger-inner::before, .hamburger-inner::after{ 
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -10px; 
}

.hamburger-inner::after {
	bottom: -10px; 
}

/*
* Hamburger Spin
*/
.hamburger--spin .hamburger-inner {
	transition-duration: 0.22s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--spin .hamburger-inner::before {
	transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
	transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active-true .hamburger-inner {
	background-color: #1D1F23;
	transform: rotate(225deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
}

.hamburger--spin.is-active-true .hamburger-inner::before {
	background-color: #1D1F23;
	top: 0;
	opacity: 0;
	transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active-true .hamburger-inner::after {
	background-color: #1D1F23;
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}


@media only screen and (max-width: 1100px) {

	.Header__navigation {
		display: none;
	}

	.Header__Avatar {
		display: none;
	}

    .hamburger {
        display: block;
		margin-left: 20px;
    }
}


@media only screen and (max-width: 380px) {

	.Header__Search {
		margin-right: 15px;
	}
}