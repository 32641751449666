/* Project Report CSS */

.ProjectReport__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ProjectReport__times {
    margin-bottom: 20px;
}

.ProjectReport__times__time {
    align-items: center;
    display: grid;
    grid-template-columns: 40px auto 150px;
}