/* StatusListItem CSS */

.StatusListItem.card {
    align-items: center;
    display: grid;
    grid-template-columns: auto 20% 20% 25px;
    height: 65px;
    margin-bottom: 20px;
    max-width: 1000px;
    padding: 0px 20px;
}

.StatusListItem__form {
    align-items: center;
    display: flex;
}

.StatusListItem__colour {
    position: relative;
}

.StatusListItem__colour__icon {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    text-align: center;
    transition: all 0.3s;
    width: 70px;
}

.StatusListItem__colour__icon:hover {
    opacity: 0.7;
}

.StatusListItem__colour__menu {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    color: #253551;
    padding: 20px 20px;
    position: absolute;
    left: -100px;
    top: calc(100% + 5px);
    width: max-content;
    z-index: 99;
}

.StatusListItem__colour__menu--position-left {
    left: auto;
    right: 100%;
}

.StatusListItem__colour__abbr {
    cursor: pointer;
    max-width: 60px;
    text-align: center;
    transition: all 0.3s;
}

.StatusListItem__colour__abbr:hover {
    opacity: 0.7;
}
